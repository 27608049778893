import {
  buildConceptDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildTestLabel, hasFeature, QUESTION_TYPES } from './questions'
import { Concept, getConcepts } from './concepts'
import { getRequireViewConceptError } from './requireViewing'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'

export type Question = {
  concepts: Concept[]
  constraints: {
    requireConceptView: boolean
  }
  directions: string
  features: {
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
  }
  id: string
  legacyOptionID: number
  response: 'completed' | null
  testing: { label: string }
  title: string
  type: 'ideaPresenter'
}

export function canContinue({ question }: { question: Question }) {
  return !getRequireViewConceptError({ question })
}

export function getDisplayError({ question }: { question: Question }) {
  return question.concepts.length > 0 ? false : 'no concepts'
}

export function getQuestion({
  id,
  question,
}: {
  id: string
  question: QuestionAPI
}) {
  return {
    concepts: getConcepts({ question }),
    constraints: {
      requireConceptView: hasFeature({ code: 'VAL02', question }),
    },
    directions: question.description,
    features: {
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
    },
    id,
    legacyOptionID: question.options[0].id,
    response: null,
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'ideaPresenter' as const,
  } satisfies Question
}

export function isIdeaPresenterQuestion(question: QuestionAPI) {
  // The API doesn't currently have an idea presenter question type. The way we know it's an
  // idea presenter is if it's a multiple choice question with more than one concept.
  return (
    question.questionTypeId === QUESTION_TYPES.MULTIPLE_CHOICE &&
    // TypeScript doesn't complain about this being undefined because our API types aren't 100% accurate.
    // I'm deferring fixing that because we have work to create a new endpoint for returning a survey
    // and hopefully at some point we'll have an idea presenter question type. For now, know that this can
    // be undefined - for example, the "question" of a "questionBlockAudience" may not have this property.
    question.conceptTestMedia &&
    question.conceptTestMedia.length > 1
  )
}
