import { compact } from 'lodash-es'

import {
  AudienceBaseSliceCategory,
  Question as QuestionAPI,
  QuestionBlock,
  Survey as SurveyAPI,
} from 'types/glass-api/domainModels'
import { BlockSet, BlockSetMonadic } from './questionBlocks'
import {
  buildExpectedResponseFromAPIAudienceAttributes,
  Condition,
  ConditionGroups,
  didSatisfyConditionGroups,
} from './conditions'
import { isIdeaPresenterQuestion } from './ideaPresenter'
import { Question, getIterationFromID } from './questions'
import { Survey } from './surveys'

export type DisplayLogic = ConditionGroups

// This is a mapping of a resource ID (e.g. option ID, concept ID, statement ID, etc.) to
// the applicable display logic.
export type DisplayLogicByResource = Record<string, DisplayLogic>

/**
 * Converts the API question block representation into a block display logic feature format
 * that's more readable and easier to work with.
 */
export function buildBlockDisplayLogic({
  questionBlock,
}: {
  questionBlock: QuestionBlock
}) {
  return buildDisplayLogicFromAPIDisplayLogic({
    groupLogic: 'or',
    resourceAudiences: questionBlock.children.map(
      ({ id, questionBlockAudiences }) => {
        return { audiences: questionBlockAudiences, id: `${id}` }
      },
    ),
  })
}

/**
 * Converts the concept display logic API feature into a format that's more readable and
 * easier to work with.
 */
export function buildConceptDisplayLogic({
  question,
}: {
  question: QuestionAPI
}) {
  return buildDisplayLogicFromAPIDisplayLogic({
    groupLogic: 'or',
    resourceAudiences: question.conceptTestMedia.map(({ audience, id }) => {
      // The "audience" structure from the API for concepts is not the same as for options
      // and questions (I believe because there's no support for multiple OR groups for
      // concepts). We add this additional array to signify a single OR group and make
      // the structure consistent for our processing.
      return { audiences: audience ? [{ audience }] : [], id: `${id}` }
    }),
  })
}

/**
 * Transforms the API "audience" concept into a display logic format that is easier to work with.
 */
export function buildDisplayLogicFromAPIDisplayLogic({
  groupLogic,
  resourceAudiences,
}: {
  groupLogic: 'and' | 'or'
  resourceAudiences: {
    audiences: {
      audience: {
        audienceSlices: {
          audienceSliceCategories: Pick<
            AudienceBaseSliceCategory,
            | 'audienceSliceCategoryAttributes'
            | 'conceptId'
            | 'logicalModifier'
            | 'question'
            | 'questionId'
          >[]
        }[]
      }
    }[]
    id: string
  }[]
}) {
  const displayLogicByResourceID: Record<string, DisplayLogic> = {}
  resourceAudiences.forEach(({ audiences, id }) => {
    // "audiences" represent display logic applied to a resource (option, question, survey, etc.).
    // Each of the audiences represents an OR group, and each of the audienceSliceCategories represents an AND group.
    displayLogicByResourceID[id] = {
      groupLogic,
      groups: compact(
        audiences.map(({ audience }) => {
          const sliceCategories =
            audience.audienceSlices[0]?.audienceSliceCategories ?? []

          if (sliceCategories.length > 0) {
            return sliceCategories.map(
              ({
                audienceSliceCategoryAttributes,
                conceptId,
                logicalModifier,
                question,
                questionId,
              }) => {
                const monadicBlockID =
                  isIdeaPresenterQuestion(question) &&
                  question.monadicId !== null
                    ? `${question.monadicId}`
                    : undefined

                return {
                  expectedResponse:
                    buildExpectedResponseFromAPIAudienceAttributes(
                      audienceSliceCategoryAttributes,
                    ),
                  item: monadicBlockID
                    ? {
                        id: monadicBlockID,
                        type: 'blockSetMonadic',
                      }
                    : {
                        conceptId: conceptId ? `${conceptId}` : null,
                        id: `${questionId}`,
                        type: 'question',
                      },
                  logic: logicalModifier,
                } satisfies Condition
              },
            )
          }
        }),
      ),
    }
  })

  return displayLogicByResourceID
}

/**
 * Converts the option display logic API feature into a format that's more readable and
 * easier to work with.
 */
export function buildOptionsDisplayLogic({
  question,
}: {
  question: QuestionAPI
}) {
  return buildDisplayLogicFromAPIDisplayLogic({
    groupLogic: 'or',
    resourceAudiences: question.options.map(
      ({ id, questionOptionAudiences }) => {
        return { audiences: questionOptionAudiences, id: `${id}` }
      },
    ),
  })
}

/**
 * Converts the question display logic API feature into a format that's more readable and
 * easier to work with.
 */
export function buildQuestionDisplayLogic({
  question,
}: {
  question: QuestionAPI
}) {
  return buildDisplayLogicFromAPIDisplayLogic({
    groupLogic: 'or',
    resourceAudiences: [question].map(({ id, questionAudiences }) => {
      return { audiences: questionAudiences, id: `${id}` }
    }),
  })[question.id]
}

/**
 * Converts the survey audience API feature into a display logic format that's more readable and
 * easier to work with.
 */
export function buildSurveyDisplayLogic({ survey }: { survey: SurveyAPI }) {
  return buildDisplayLogicFromAPIDisplayLogic({
    groupLogic: 'and',
    resourceAudiences: [
      {
        // The API format for survey audiences is different than for questions, options,
        // and concepts. The entries in the audienceSlices array represent conditions that
        // should be OR'ed, as opposed to AND'ed. We transform this structure to look like
        // audiences for the other resources, so we can construct a common display logic
        // structure.
        audiences:
          survey.audience?.audienceSlices.map((audienceSlice) => {
            return { audience: { audienceSlices: [audienceSlice] } }
          }) ?? [],
        id: `${survey.id}`,
      },
    ],
  })[survey.id]
}

/**
 * Filters the blocks of the provided block set based on the choices made by the user
 * and the configured display logic.
 */
export function filterBlocksByDisplayLogic({
  blockSet,
  monadicBlocks,
  questions,
}: {
  blockSet: BlockSet
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
}) {
  return filterResourcesByDisplayLogic({
    displayLogicByResourceID: blockSet.features.blockDisplayLogic,
    monadicBlocks,
    questions,
    resources: blockSet.blocks,
  })
}

/**
 * Filters the concepts of the provided next question based on the choices made by the user
 * and the configured display logic.
 */
export function filterConceptsByDisplayLogic<QuestionType extends Question>({
  monadicBlocks,
  nextQuestion,
  questions,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  nextQuestion: QuestionType
  questions: Record<string, Question>
}) {
  return {
    ...nextQuestion,
    concepts: filterResourcesByDisplayLogic({
      displayLogicByResourceID: nextQuestion.features.conceptDisplayLogic,
      monadicBlocks,
      nextQuestionID: nextQuestion.id,
      questions,
      resources: nextQuestion.concepts,
    }),
  }
}

/**
 * Filters the provided question based on the choices made by the user and the configured
 * display logic. This essentially returns a truthy value if the question passed display logic
 * and a falsy value if it did not.
 */
export function filterQuestionByDisplayLogic<QuestionType extends Question>({
  monadicBlocks,
  nextQuestion,
  questions,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  nextQuestion: QuestionType
  questions: Record<string, Question>
}) {
  return filterResourcesByDisplayLogic({
    displayLogicByResourceID: {
      [nextQuestion.id]: nextQuestion.features.displayLogic,
    },
    monadicBlocks,
    nextQuestionID: nextQuestion.id,
    questions,
    resources: [nextQuestion],
  })[0]
}

/**
 * Filters the provided resources based on the choices made by the user and the
 * configured display logic.
 *
 * If a nextQuestionID is provided, we'll use that to determine the current iteration
 * for checking configured display logic. For example, if the nextQuestionID is 10-0,
 * we'll identify "0" as the current iteration and if display logic references question
 * 8, we'll check the response to "8-0" to ensure we're looking at the right iteration.
 *
 * Not all resources will be in the context of a next question: for example, filtering surveys
 * or question blocks does not happen within a question iteration.
 */
export function filterResourcesByDisplayLogic<
  ResourceType extends { id: string },
>({
  displayLogicByResourceID,
  monadicBlocks,
  nextQuestionID,
  questions,
  resources,
}: {
  displayLogicByResourceID: Record<string, DisplayLogic>
  monadicBlocks: Record<string, BlockSetMonadic>
  nextQuestionID?: string
  questions: Record<string, Question>
  resources: ResourceType[]
}) {
  return resources.filter(({ id }) => {
    const displayLogic = displayLogicByResourceID[id]

    // Not every resource will have display logic applied. If that's the case, we don't
    // want to filter it out.
    if (!displayLogic || displayLogic.groups.length === 0) {
      return true
    }

    return didSatisfyConditionGroups({
      conditionGroups: displayLogic,
      curIteration: nextQuestionID
        ? getIterationFromID(nextQuestionID)
        : undefined,
      monadicBlocks,
      questions,
    })
  })
}

export function filterQuestionResourcesByDisplayLogic<
  QuestionType extends Question,
>({
  monadicBlocks,
  nextQuestion,
  questions,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  nextQuestion: QuestionType
  questions: Record<string, Question>
}) {
  const { collection, feature, key } = getQuestionResourcesToFilter({
    question: nextQuestion,
  })
  if (collection.length === 0 || !key || !feature) {
    return nextQuestion
  }

  return {
    ...nextQuestion,
    [key]: filterResourcesByDisplayLogic({
      displayLogicByResourceID: feature,
      monadicBlocks,
      nextQuestionID: nextQuestion.id,
      questions,
      resources: collection,
    }),
  }
}

/**
 * Filters the provided survey based on the choices made by the user and the configured
 * display logic. In the case of a survey, the display logic is in the form of answers
 * to demographic questions.
 */
export function filterSurveyByDisplayLogic({
  monadicBlocks,
  questions,
  survey,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  survey: Survey
}): Survey | undefined {
  return filterResourcesByDisplayLogic({
    displayLogicByResourceID: { [survey.id]: survey.features.displayLogic },
    monadicBlocks,
    questions,
    resources: [survey],
  })[0]
}

/**
 * Different things will be filtered out from a question depending on its type. For example,
 * multiple choice questions could have options filtered by display logic while scale questions
 * could have scales filtered. This function returns the collection to filter, the configured
 * display logic feature, and a string key identifying the collection type (e.g. "statements", "options",
 * "scales", etc.).
 */
export function getQuestionResourcesToFilter({
  question,
}: {
  question: Question
}) {
  const resourcesToFilter: {
    collection: { id: string; title: string }[]
    feature: DisplayLogicByResource | undefined
    key: 'options' | 'scales' | 'statements' | undefined
  } = { collection: [], feature: undefined, key: undefined }

  if (question.type === 'matrix') {
    resourcesToFilter.collection = question.statements
    resourcesToFilter.feature = question.features.statementDisplayLogic
    resourcesToFilter.key = 'statements'
  } else if (
    question.type === 'multipleChoice' ||
    question.type === 'ranking'
  ) {
    resourcesToFilter.collection = question.options
    resourcesToFilter.feature = question.features.optionDisplayLogic
    resourcesToFilter.key = 'options'
  } else if (question.type === 'scale') {
    resourcesToFilter.collection = question.scales
    resourcesToFilter.feature = question.features.scaleDisplayLogic
    resourcesToFilter.key = 'scales'
  }

  return resourcesToFilter
}
