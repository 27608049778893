import { InputHTMLAttributes } from 'react'
import { orderBy, sumBy } from 'lodash-es'
import { ValidationValueMessage } from 'react-hook-form'

import { buildCarryForwardFeature, CarryForwardFeature } from './carryForward'
import {
  buildConceptDisplayLogic,
  buildOptionsDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildExclusiveMatrixOptionIDs } from './exclusive'
import {
  buildOptionDisplayXOfYFeature,
  DisplayXOfYFeature,
} from './displayXOfY'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import {
  buildRandomizeMatrixOptionsFeature,
  buildRandomizeOptionsFeature,
  RandomizeFeature,
} from './randomize'
import {
  buildTestLabel,
  getConstraintMinMax,
  hasFeature,
  OPTION_TYPES,
  shouldHideImageTitles,
} from './questions'
import { Concept, getConcepts } from './concepts'
import { Image } from './media'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'
import { Survey } from './surveys'

export type Option = {
  constraints: {
    max: ValidationValueMessage<number> | undefined
    min: ValidationValueMessage<number> | undefined
  }
  freeTextInputType: InputHTMLAttributes<HTMLInputElement>['type']
  id: string
  title: string
}

export type Question = {
  concepts: Concept[]
  constraints: {
    exclusiveOptionIDs: Set<string>
    requireConceptView: boolean
    singleChoice: boolean
  }
  directions: string
  displayType: 'disclosure' | 'table' | 'table-inverted'
  features: {
    carryForward: CarryForwardFeature | undefined
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    displayXOfY: DisplayXOfYFeature | undefined
    pipeConcept: PipeConceptFeature | undefined
    randomizeOptions: RandomizeFeature | undefined
    randomizeStatements: RandomizeFeature | undefined
    scroll: boolean
    statementDisplayLogic: DisplayLogicByResource
  }
  id: string
  options: Option[]
  // This is a mapping of statementID to a set of option IDs.
  response: Record<string, Set<string>>
  // This is a mapping of statementID to a mapping of optionID to free text.
  // For example: { '1': { '1': 'free text response' } } means that option ID 1
  // for statement ID 1 has a free text response of 'free text response'.
  responseFreeText: Record<string, Record<string, string>>
  statements: Statement[]
  testing: { label: string }
  title: string
  type: 'matrix'
}

export type Statement = {
  features: { hideTitle: boolean }
  id: string
  image?: Image
  title: string
}

export function canContinue({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  const numAnsweredStatements = sumBy(Object.values(curResponse), (options) =>
    options.size > 0 ? 1 : 0,
  )

  return numAnsweredStatements === question.statements.length
}

export function getDisplayError({ question }: { question: Question }) {
  const errors: string[] = []

  if (question.statements.length === 0) {
    errors.push('no statements')
  }

  if (question.options.length === 0) {
    errors.push('no options')
  }

  return errors.length > 0 ? errors.join(', ') : false
}

function getMatrixOptions({
  question,
  survey,
}: {
  question: QuestionAPI
  survey: Survey
}) {
  if (survey.features.useNewMatrixOptions) {
    const sortedOptions = orderBy(question.matrixOptions, (o) => o.sort, 'asc')

    return sortedOptions.map((matrixOption) => {
      const { max, min } = getConstraintMinMax({ question, survey })
      const constraints = { max, min }

      // The only way to turn free text responses into number types is to have
      // a min and max constraint.
      const freeTextInputType = matrixOption.isFreeText
        ? max || min
          ? 'number'
          : 'text'
        : undefined

      return {
        constraints,
        freeTextInputType,
        id: `${matrixOption.id}`,
        title: matrixOption.title,
      } satisfies Option
    })
  }

  if (question.labels) {
    return question.labels?.map((label) => {
      const { max, min } = getConstraintMinMax({ question, survey })
      const constraints = { max, min }

      // The only way to turn free text responses into number types is to have
      // a min and max constraint.
      const freeTextInputType = label.isFreeText
        ? max || min
          ? 'number'
          : 'text'
        : undefined

      return {
        constraints,
        freeTextInputType,
        id: `${label.id}`,
        title: label.optionLabel,
      } satisfies Option
    })
  }

  return []
}

export function getQuestion({
  id,
  question,
  questionsConfiguration,
  survey,
}: {
  id: string
  question: QuestionAPI
  questionsConfiguration: Record<number, QuestionAPI>
  survey: Survey
}) {
  return {
    concepts: getConcepts({ question }),
    constraints: {
      exclusiveOptionIDs: buildExclusiveMatrixOptionIDs({ question, survey }),
      requireConceptView: hasFeature({ code: 'VAL02', question }),
      singleChoice: !hasFeature({ code: 'QMCM01', question }),
    },
    directions: question.description,
    displayType: hasFeature({ code: 'OM01', question })
      ? hasFeature({ code: 'OMI01', question })
        ? 'table-inverted'
        : 'table'
      : 'disclosure',
    features: {
      carryForward: buildCarryForwardFeature({
        question,
        questionsConfiguration,
        useNewMatrixOptions: survey.features.useNewMatrixOptions,
      }),
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      displayXOfY: buildOptionDisplayXOfYFeature({ question }),
      pipeConcept: buildPipeConceptFeature({ question }),
      randomizeOptions: buildRandomizeMatrixOptionsFeature({
        question,
        survey,
      }),
      randomizeStatements: buildRandomizeOptionsFeature({ question }),
      scroll: hasFeature({ code: 'OMS01', question }),
      statementDisplayLogic: buildOptionsDisplayLogic({ question }),
    },
    id,
    options: getMatrixOptions({ question, survey }),
    response: {},
    responseFreeText: {},
    statements: question.options.map((option) => {
      const isImage = question.contentTypeId === OPTION_TYPES.IMAGE
      const title = isImage ? (option.description ?? '') : option.title

      return {
        features: { hideTitle: shouldHideImageTitles(question) },
        id: `${option.id}`,
        image:
          isImage && option.dataUrl
            ? {
                alt: title,
                publicID: option.dataUrl.public_id,
              }
            : undefined,
        title,
      } satisfies Statement
    }),
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'matrix' as const,
  } satisfies Question
}

export function isExclusiveOptionSelected({
  question,
  selectedOptions,
}: {
  question: Question
  selectedOptions: Set<string>
}) {
  return Array.from(question.constraints.exclusiveOptionIDs).some((id) => {
    return selectedOptions.has(id)
  })
}

export function isOptionDisabled({
  option,
  question,
  selectedOptions,
}: {
  option: Question['options'][number]
  question: Question
  selectedOptions: Question['response'][number]
}) {
  // If the option has been selected, we don't want to disable it because we want the
  // user to be able to deselect it.
  if (selectedOptions.has(option.id)) {
    return false
  }

  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  if (isExclusiveOptionSelected({ question, selectedOptions })) {
    return true
  }

  return false
}
