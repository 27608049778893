import { cloneDeep, compact } from 'lodash-es'

import {
  applyBlockFeatures,
  BlockSet,
  BlockSetMonadic,
  buildBlockSet,
  buildBlockSetMonadic,
  hasMonadicBlockPromptDisplayError,
} from './questionBlocks'
import { buildSurveyDisplayLogic, DisplayLogic } from './displayLogic'
import { buildVariableQuotas } from './quotas'
import { ConditionGroups } from './conditions'
import {
  EndMessage,
  Language,
  Survey as SurveyAPI,
  SurveyButtonText,
  SurveyQualityCheck,
  SurveyVariableQuota,
} from 'types/glass-api/domainModels'
import { getDisplayError, Question } from './questions'

export interface Survey {
  customizations: {
    buttonText: SurveyButtonText | null
    endMessages: EndMessage[]
  }
  features: { displayLogic: DisplayLogic; useNewMatrixOptions: boolean }
  hash: string
  id: string
  language: Language
  qualityChecks: SurveyQualityCheck[]
  quotas: {
    variables: VariableQuota[]
  }
  respondents: {
    numCompleted: number
    numNeeded: number
  }
  title: string
  waveID: string
}

interface SurveyItemQuestion {
  data: { id: string }
  type: 'question'
}

export interface SurveyItemBlockSet {
  data: BlockSet
  type: 'blockSet'
}

export interface SurveyItemBlockSetMonadic {
  data: BlockSetMonadic
  type: 'blockSetMonadic'
}

export type SurveyItem =
  | SurveyItemBlockSet
  | SurveyItemBlockSetMonadic
  | SurveyItemQuestion

export type VariableQuota = {
  segments: {
    conditionGroups: ConditionGroups
    quotaType: SurveyVariableQuota['type']
    title: string
  }[]
  title: string
}

/**
 * Processes the questions in a survey and groups them by blocks to create a list of
 * survey items that can more easily be worked with when showing a respondent a survey.
 */
export function buildSurveyItems({ survey }: { survey: SurveyAPI }) {
  const processedBlockSetIDs = new Set<string>()

  return compact(
    survey.questions.map((question) => {
      if (question.monadicId) {
        // The list of questions for a survey is a flat list, which means that questions
        // in a monadic block set will show in future iterations. However, our survey items processes
        // a monadic block set all at once, so once we've seen it, we don't need to process it again.
        //
        // For example, we could have questions: [{id: 1, monadicId: 1}, {id: 2, monadicId: 1}].
        // Once we've processed question with ID 1, we've processed all other questions in monadic block
        // 1, so we can ignore question 2.
        if (processedBlockSetIDs.has(`blockSetMonadic-${question.monadicId}`)) {
          return
        }

        processedBlockSetIDs.add(`blockSetMonadic-${question.monadicId}`)

        return {
          data: buildBlockSetMonadic({
            monadicID: question.monadicId,
            iterations: question.monadicBlockSequences ?? 1,
            questionIDs: survey.questions
              .filter((q) => q.monadicId === question.monadicId)
              .map(({ id }) => id),
          }),
          type: 'blockSetMonadic',
        }
      }

      const questionBlock = survey.questionBlocks.find((block) =>
        block.children.find(({ id }) => id === question.blockId),
      )

      if (questionBlock) {
        // The list of questions for a survey is a flat list, which means that questions
        // in a block set will show in future iterations. However, our survey items processes
        // a block set all at once, so once we've seen it, we don't need to process it again.
        //
        // For example, we could have questions: [{id: 1, blockId: 1}, {id: 2, blockId: 1}].
        // Once we've processed question with ID 1, we've processed all other questions in block
        // 1, so we can ignore question 2.
        if (processedBlockSetIDs.has(`blockSet-${questionBlock.id}`)) {
          return
        }

        processedBlockSetIDs.add(`blockSet-${questionBlock.id}`)

        return {
          data: buildBlockSet({ questionBlock }),
          type: 'blockSet',
        } satisfies SurveyItemBlockSet
      }

      return {
        data: { id: `${question.id}` },
        type: 'question',
      } satisfies SurveyItemQuestion
    }),
  ) satisfies SurveyItem[]
}

/**
 * Returns a list of questions that were displayed in the survey with their associated
 * monadic concept ID, if relevant. The monadic concept is only relevant for questions
 * of a monadic block set.
 */
export function getDisplayedQuestionsWithMonadicConceptIDs({
  monadicBlocks,
  questions,
  surveyItems,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  surveyItems: SurveyItem[]
}): { monadicConceptID?: number; question: Question }[] {
  return surveyItems
    .flatMap((surveyItem) => {
      if (surveyItem.type === 'blockSet') {
        return surveyItem.data.blocks.flatMap((block) => {
          return block.questionIDs.map((questionID) => {
            const question = questions[questionID]

            return { question }
          })
        })
      } else if (surveyItem.type === 'blockSetMonadic') {
        let monadicConceptID: number | undefined

        return surveyItem.data.blocks.flatMap((block) => {
          const monadicBlockHasError = hasMonadicBlockPromptDisplayError({
            block,
            blockSetType: surveyItem.type,
            monadicBlocks,
            questions,
          })

          // A monadic block could have a display error if display logic filtered out
          // the prompt, every question in the block, or all the concepts to prompt.
          if (monadicBlockHasError) {
            return []
          }

          return block.questionIDs.map((questionID, i) => {
            const question = questions[questionID]

            // The first question of a block in a monadic block set is currently
            // expected to be the Idea Presenter question that has the concept for
            // the block. We display the first concept in a question's list of concepts.
            if (i === 0) {
              monadicConceptID = Number(question.concepts[0]?.id)
            }

            return { monadicConceptID, question }
          })
        })
      }

      return { question: questions[surveyItem.data.id] }
    })
    .filter(({ question }) => {
      return !getDisplayError({ monadicBlocks, question, questions })
    })
}

export function getEndMessageWithDefault({
  fallback,
  survey,
  type,
}: {
  fallback: { subtitle: string; title: string }
  survey: Survey
  type: EndMessage['type']
}) {
  const message = survey.customizations.endMessages.find(
    ({ type: t }) => t === type,
  )

  return message
    ? {
        subtitle: message.subtitle ?? '',
        title: message.title,
      }
    : fallback
}

/**
 * Returns the messages to show for different scenarios in a survey such as completion, disqualification, etc.
 */
export function getEndMessages({ survey }: { survey: Survey }) {
  return {
    closed: getEndMessageWithDefault({
      fallback: {
        subtitle:
          'We appreciate your interest in this survey. However, it is not currently accepting responses.',
        title: 'Thank you',
      },
      survey,
      type: 'closed',
    }),
    completed: getEndMessageWithDefault({
      fallback: {
        subtitle: 'Thanks for completing this survey.',
        title: "You're all done!",
      },
      survey,
      type: 'completed',
    }),
    disqualified: getEndMessageWithDefault({
      fallback: {
        subtitle: "You didn't qualify in the audience for this campaign.",
        title: "Sorry, you didn't qualify",
      },
      survey,
      type: 'disqualified',
    }),
  }
}

/**
 * Returns the question ID of the next or previous question in the block set.
 * This will return the first question of the next block or the last question
 * of the previous block if there are no more questions in the current block
 * or if the current block is monadic and the prompt question couldn't be shown.
 */
export function getNextBlockSetQuestionID({
  blockSet,
  blockSetType,
  curQuestionID,
  direction,
  monadicBlocks,
  questions,
}: {
  blockSet: BlockSet | BlockSetMonadic
  blockSetType: 'blockSet' | 'blockSetMonadic'
  curQuestionID: string
  direction: 'next' | 'previous'
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
}) {
  let nextQuestionID: string | undefined
  const { blocks } = blockSet

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i]
    const questionIndexInBlock = block.questionIDs.findIndex(
      (id) => id === curQuestionID,
    )

    if (questionIndexInBlock === -1) {
      continue
    }

    const blockPromptHasDisplayError = hasMonadicBlockPromptDisplayError({
      block,
      blockSetType,
      monadicBlocks,
      questions,
    })

    if (!blockPromptHasDisplayError) {
      const nextIndex =
        direction === 'next'
          ? questionIndexInBlock + 1
          : questionIndexInBlock - 1
      nextQuestionID = block.questionIDs[nextIndex]
    }

    // nextQuestionID would be undefined here if we were on the last question in
    // the block or if the block was a monadic block and the prompt question was
    // not displayable.
    if (!nextQuestionID) {
      // If we have more blocks, we want to return the first question in
      // the next block.
      if (direction === 'next' && i < blocks.length - 1) {
        const nextBlock = blocks[i + 1]

        nextQuestionID = nextBlock.questionIDs[0]
      }
      // If we have previous blocks, we want to return the last question in
      // the previous block.
      else if (direction === 'previous' && i > 0) {
        const previousBlock = blocks[i - 1]

        nextQuestionID =
          previousBlock.questionIDs[previousBlock.questionIDs.length - 1]
      }
    }
  }

  return nextQuestionID
}

/**
 * Returns the next question ID of the next or previous question in the block set.
 * This will return the first question of the first block or the last question
 * of the last block.
 *
 * When going in the "next" direction, block set features will be applied before selecting
 * the next question and the adjusted block set will be returned; this is done because blocks
 * may be filtered out due to features (e.g. display logic, display X of Y, etc.) that are
 * dependent on responses.
 */
export function getNextQuestionFromBlockSet<
  BlockSetType extends SurveyItemBlockSet | SurveyItemBlockSetMonadic,
>({
  direction,
  monadicBlocks,
  questions,
  surveyItem,
}: {
  direction: 'next' | 'previous'
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  surveyItem: BlockSetType
}) {
  if (direction === 'next') {
    const nextSurveyItem = cloneDeep(surveyItem)
    if (surveyItem.type === 'blockSet') {
      nextSurveyItem.data = applyBlockFeatures({
        blockSet: surveyItem.data,
        monadicBlocks,
        questions,
      })
    }

    return {
      // It's possible after applying features, we no longer have any more
      // blocks in our block set (e.g. all blocks were filtered out by display logic).
      nextQuestionID: nextSurveyItem.data.blocks[0]?.questionIDs[0],
      nextSurveyItem,
    }
  }

  const blocks = surveyItem.data.blocks
  let curBlockIndex = blocks.length - 1

  // If we're going backwards we may potentially be trying to enter a monadic
  // block where some of the iterations couldn't be shown due to the prompt not
  // being displayable. We need to loop to find the first block that didn't have
  // an issue being shown.
  while (blocks[curBlockIndex]) {
    if (
      !hasMonadicBlockPromptDisplayError({
        block: blocks[curBlockIndex],
        blockSetType: surveyItem.type,
        monadicBlocks,
        questions,
      })
    ) {
      break
    }

    curBlockIndex = curBlockIndex - 1
  }

  const { questionIDs = [] } = blocks[curBlockIndex] ?? {}

  return {
    // In this case we're going to the previous question so we get the last
    // question of the last block.
    nextQuestionID: questionIDs[questionIDs.length - 1],
    nextSurveyItem: surveyItem,
  }
}

/**
 * Returns the next question ID according to the provided direction. The returned
 * nextQuestionID will be undefined if we're at the beginning or end of the survey.
 *
 * When going in the "next" direction, block set features will be applied before selecting
 * the next question and the adjusted block set will be returned in the surveyItems property.
 */
export function getNextQuestionID({
  curQuestionID,
  direction,
  monadicBlocks,
  questions,
  surveyItems,
}: {
  curQuestionID: string
  direction: 'next' | 'previous'
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  surveyItems: SurveyItem[]
}) {
  const curItemIndex = getSurveyItemIndexFromQuestionID({
    questionID: curQuestionID,
    surveyItems,
  })
  const curItem = surveyItems[curItemIndex]
  if (!curItem) {
    return { nextQuestionID: undefined, surveyItems }
  }

  if (isBlockSet(curItem)) {
    const nextQuestionID = getNextBlockSetQuestionID({
      blockSet: curItem.data,
      blockSetType: curItem.type,
      curQuestionID,
      direction,
      monadicBlocks,
      questions,
    })

    // We may not have a next question ID if the current question was the last question
    // in the last block of the block set or all remaining blocks were filtered out due
    // to block set features (e.g. display logic, display X of Y, etc.).
    if (nextQuestionID) {
      return { nextQuestionID, surveyItems }
    }
  }

  // We may adjust our survey items as we get to question blocks and apply
  // features such as display X of Y or display logic.
  const newSurveyItems = [...surveyItems]

  let nextItemIndex = direction === 'next' ? curItemIndex + 1 : curItemIndex - 1
  let nextItem = newSurveyItems[nextItemIndex]

  while (nextItem) {
    if (isBlockSet(nextItem)) {
      // When we go in the "next" direction, we'll apply block set features (like display logic
      // or display X of Y) so we need to adjust our survey items with the new block set because
      // it may be different (e.g. have filtered out blocks).
      const { nextSurveyItem, nextQuestionID } = getNextQuestionFromBlockSet({
        direction,
        monadicBlocks,
        questions,
        surveyItem: nextItem,
      })

      newSurveyItems[nextItemIndex] = nextSurveyItem

      // If we don't have a question ID for our next block set, then the applied
      // features caused all blocks to be filtered out. So we move on to the next
      // survey item.
      if (!nextQuestionID) {
        nextItemIndex =
          direction === 'next' ? nextItemIndex + 1 : nextItemIndex - 1
        nextItem = surveyItems[nextItemIndex]

        continue
      }

      return { nextQuestionID, surveyItems: newSurveyItems }
    }

    // If we're at this point, the next item is a question type.
    return { nextQuestionID: nextItem.data.id, surveyItems: newSurveyItems }
  }

  // If we get here, we ran out of next survey items (i.e. we're at the beginning
  // or end of the survey).
  return { nextQuestionID: undefined, surveyItems: newSurveyItems }
}

/**
 * Returns a flattened array of all question IDs in the provided survey item.
 */
export function getQuestionIDsFromSurveyItem({
  surveyItem,
}: {
  surveyItem: SurveyItem
}) {
  if (surveyItem.type === 'question') {
    return [surveyItem.data.id]
  }

  return surveyItem.data.blocks.flatMap(({ questionIDs }) => questionIDs)
}

/**
 * Returns a flattened array of all question IDs in the provided survey items.
 */
export function getQuestionIDsFromSurveyItems({
  surveyItems,
}: {
  surveyItems: SurveyItem[]
}) {
  return surveyItems.flatMap((surveyItem) => {
    return getQuestionIDsFromSurveyItem({ surveyItem })
  })
}

/**
 * Transforms the API survey representation into a format that's more readable
 * and easier to work with.
 */
export function getSurvey({ survey }: { survey: SurveyAPI }) {
  return {
    customizations: {
      buttonText: survey.customizations.buttonText,
      endMessages: survey.customizations.endMessages,
    },
    features: {
      displayLogic: buildSurveyDisplayLogic({ survey }),
      useNewMatrixOptions: survey.useNewMatrixOptions,
    },
    hash: survey.hash,
    id: `${survey.id}`,
    language: survey.language,
    qualityChecks: survey.qualityChecks,
    quotas: {
      variables: buildVariableQuotas({ survey }),
    },
    respondents: {
      numCompleted: survey.numberOfCompletes,
      numNeeded: survey.participants,
    },
    title: survey.title,
    waveID: `${survey.waveId}`,
  } satisfies Survey
}

/**
 * Returns the index of the survey item that contains the provided question ID.
 */
export function getSurveyItemIndexFromQuestionID({
  questionID,
  surveyItems,
}: {
  questionID: string
  surveyItems: SurveyItem[]
}) {
  return surveyItems.findIndex((item) => {
    if (isBlockSet(item)) {
      return item.data.blocks.find((block) => {
        return block.questionIDs.includes(questionID)
      })
    }

    return item.data.id === questionID
  })
}

export function isBlockSet(
  surveyItem: SurveyItem,
): surveyItem is SurveyItemBlockSet | SurveyItemBlockSetMonadic {
  return surveyItem.type === 'blockSet' || surveyItem.type === 'blockSetMonadic'
}

export function isCurrentQuestionFirst({
  curQuestionID,
  surveyItems,
}: {
  curQuestionID: string
  surveyItems: SurveyItem[]
}) {
  const firstItem = surveyItems[0]
  if (firstItem.type === 'question') {
    return firstItem.data.id === curQuestionID
  }

  return firstItem.data.blocks[0].questionIDs[0] === curQuestionID
}

export function isCurrentQuestionLast({
  curQuestionID,
  surveyItems,
}: {
  curQuestionID: string
  surveyItems: SurveyItem[]
}) {
  const lastItem = surveyItems[surveyItems.length - 1]
  if (lastItem.type === 'question') {
    return lastItem.data.id === curQuestionID
  }

  const blocks = lastItem.data.blocks
  const lastBlock = blocks[blocks.length - 1]

  return (
    lastBlock.questionIDs[lastBlock.questionIDs.length - 1] === curQuestionID
  )
}

export function isQualityCheckEnabled({
  qualityChecks,
  type,
}: {
  qualityChecks: SurveyQualityCheck[]
  type: SurveyQualityCheck['type']
}) {
  // We default to "true" to be extra careful and enforce quality checks if they're not
  // explicitly enabled or disabled.
  return qualityChecks.find((qc) => qc.type === type)?.enabled ?? true
}
