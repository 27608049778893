import { shuffle } from 'lodash-es'

import { BlockSet } from './questionBlocks'
import { hasFeature, Question } from './questions'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'
import { Survey } from './surveys'

export type RandomizeFeature = {
  anchoredResourceIDs: Set<string>
}

/**
 * Converts the randomize matrix options API feature into a format that's more readable
 * and easier to work with.
 */
export function buildRandomizeMatrixOptionsFeature({
  question,
  survey,
}: {
  question: QuestionAPI
  survey: Survey
}) {
  if (
    !hasFeature({ code: 'RDM02', question }) ||
    (question.matrixOptions.length === 0 && !question.labels)
  ) {
    return
  }

  let resources: string[] = []
  if (survey.features.useNewMatrixOptions) {
    resources = question.matrixOptions
      .filter((o) => o.isAnchored)
      .map(({ id }) => `${id}`)
  } else if (question.labels) {
    resources = question.labels
      .filter(
        ({ labelFeatures }) =>
          !!labelFeatures.find((feature) => feature.feature.code === 'QOA01'),
      )
      .map(({ id }) => `${id}`)
  }

  return {
    anchoredResourceIDs: new Set(resources),
  } satisfies RandomizeFeature
}

/**
 * Converts the randomize options API feature into a format that's more readable
 * and easier to work with.
 */
export function buildRandomizeOptionsFeature({
  question,
}: {
  question: QuestionAPI
}) {
  if (!hasFeature({ code: 'RDM01', question })) {
    return
  }

  return {
    anchoredResourceIDs: new Set(
      question.options
        .filter(({ anchored }) => anchored)
        .map(({ id }) => `${id}`),
    ),
  } satisfies RandomizeFeature
}

export function randomizeBlocks({ blockSet }: { blockSet: BlockSet }) {
  if (!blockSet.features.randomize) {
    return blockSet.blocks
  }

  return shuffle(blockSet.blocks)
}

export function randomizeQuestionResources({
  nextQuestion,
}: {
  nextQuestion: Question
}) {
  const resourcesToRandomize = getQuestionResourcesToRandomize({
    question: nextQuestion,
  })
  if (resourcesToRandomize.length === 0) {
    return nextQuestion
  }

  let adjustedQuestion = nextQuestion
  for (const { collection, feature, key } of resourcesToRandomize) {
    const { anchoredResourceIDs } = feature

    const shuffledResources = shuffle(
      collection.filter(({ id }) => {
        return !anchoredResourceIDs.has(id)
      }),
    )

    adjustedQuestion = {
      ...adjustedQuestion,
      [key]: collection.map((resource) => {
        if (anchoredResourceIDs.has(resource.id)) {
          return resource
        }

        return shuffledResources.splice(0, 1)[0]
      }),
    }
  }

  return adjustedQuestion
}

/**
 * Different things will be randomized in a question depending on its type. For example,
 * multiple choice questions could have options randomized while scale questions could
 * have scales randomized. This function returns the collection to randomize, the configured
 * randomization feature, and a string key identifying the collection type (e.g. "statements",
 * "options", "scales", etc.).
 */
function getQuestionResourcesToRandomize({ question }: { question: Question }) {
  const resourcesToRandomize: {
    collection: { id: string }[]
    feature: RandomizeFeature
    key: 'options' | 'scales' | 'statements'
  }[] = []
  if (question.type === 'matrix' && question.features.randomizeStatements) {
    resourcesToRandomize.push({
      collection: question.statements,
      feature: question.features.randomizeStatements,
      key: 'statements',
    })
  }

  if (
    (question.type === 'matrix' ||
      question.type === 'multipleChoice' ||
      question.type === 'ranking') &&
    question.features.randomizeOptions
  ) {
    resourcesToRandomize.push({
      collection: question.options,
      feature: question.features.randomizeOptions,
      key: 'options',
    })
  }

  if (question.type === 'scale' && question.features.randomizeScales) {
    resourcesToRandomize.push({
      collection: question.scales,
      feature: question.features.randomizeScales,
      key: 'scales',
    })
  }

  return resourcesToRandomize
}
