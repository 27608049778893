import { buildCarryForwardFeature, CarryForwardFeature } from './carryForward'
import {
  buildConceptDisplayLogic,
  buildOptionsDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildExclusiveOptionIDs } from './exclusive'
import { buildOptionQuotas, OptionQuotasConstraint } from './quotas'
import {
  buildOptionDisplayXOfYFeature,
  DisplayXOfYFeature,
} from './displayXOfY'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import { buildRandomizeOptionsFeature, RandomizeFeature } from './randomize'
import {
  buildTestLabel,
  getFeatureNumberRangeValue,
  hasFeature,
  OPTION_TYPES,
  shouldHideImageTitles,
} from './questions'
import { Concept, getConcepts } from './concepts'
import { getMinMaxError } from './minMax'
import {
  getRequireViewConceptError,
  getRequireViewImagesError,
} from './requireViewing'
import { Image } from './media'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'
import { Survey } from './surveys'

export type OptionImage = {
  constraints: {
    requireView: boolean
  }
  features: { hideTitle: boolean }
  id: string
  image: Image & { viewed: boolean }
  title: string
  type: 'image'
}

export type OptionText = {
  id: string
  title: string
  type: 'text'
}

export type Question = {
  concepts: Concept[]
  constraints: {
    exclusiveOptionIDs: Set<string>
    max: number | null
    min: number | null
    optionQuotas?: OptionQuotasConstraint
    requireConceptView: boolean
    requireImageViews: boolean
  }
  directions: string
  features: {
    carryForward: CarryForwardFeature | undefined
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    displayXOfY: DisplayXOfYFeature | undefined
    optionDisplayLogic: DisplayLogicByResource
    pipeConcept: PipeConceptFeature | undefined
    randomizeOptions: RandomizeFeature | undefined
  }
  id: string
  options: (OptionText | OptionImage)[]
  // This is an ordered list of option IDs the respondent ranked.
  response: string[]
  testing: { label: string }
  title: string
  type: 'ranking'
}

export function canContinue({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  return (
    hasSelectedMinOptions({ curResponse, question }) &&
    !getMinMaxError({ curResponse: new Set(curResponse), question }) &&
    !getRequireViewConceptError({ question }) &&
    !getRequireViewImagesError({ question })
  )
}

export function getDisplayError({ question }: { question: Question }) {
  return question.options.length > 0 ? false : 'no options'
}

export function getQuestion({
  id,
  question,
  questionsConfiguration,
  survey,
}: {
  id: string
  question: QuestionAPI
  questionsConfiguration: Record<number, QuestionAPI>
  survey: Survey
}) {
  return {
    concepts: getConcepts({ question }),
    constraints: {
      exclusiveOptionIDs: buildExclusiveOptionIDs({ question }),
      max: getFeatureNumberRangeValue({ code: 'MCUL01', question }),
      min: getFeatureNumberRangeValue({ code: 'MCLL01', question }),
      optionQuotas: buildOptionQuotas({ question, survey }),
      requireConceptView: hasFeature({ code: 'VAL02', question }),
      requireImageViews: hasFeature({ code: 'VAL01', question }),
    },
    directions: question.description,
    features: {
      carryForward: buildCarryForwardFeature({
        question,
        questionsConfiguration,
        useNewMatrixOptions: survey.features.useNewMatrixOptions,
      }),
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      displayXOfY: buildOptionDisplayXOfYFeature({ question }),
      optionDisplayLogic: buildOptionsDisplayLogic({ question }),
      pipeConcept: buildPipeConceptFeature({ question }),
      randomizeOptions: buildRandomizeOptionsFeature({ question }),
    },
    id,
    options: question.options.map((option) => {
      if (question.contentTypeId === OPTION_TYPES.IMAGE && option.dataUrl) {
        const title = option.description ?? ''

        return {
          constraints: { requireView: option.viewRequired },
          features: { hideTitle: shouldHideImageTitles(question) },
          id: `${option.id}`,
          image: {
            alt: title,
            publicID: option.dataUrl.public_id,
            viewed: false,
          },
          title,
          type: 'image' as const,
        } satisfies OptionImage
      }

      return {
        id: `${option.id}`,
        title: option.title,
        type: 'text' as const,
      } satisfies OptionText
    }),
    response: [],
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'ranking' as const,
  } satisfies Question
}

function isExclusiveOptionSelected({
  curResponse,
  question,
}: {
  curResponse: string[]
  question: Question
}) {
  return Array.from(question.constraints.exclusiveOptionIDs).some((id) => {
    return curResponse.includes(id)
  })
}

/**
 * Returns a boolean indicating if the respondent has ranked the minimum number of
 * options required to rank.
 */
function hasSelectedMinOptions({
  curResponse,
  question,
}: {
  curResponse: string[]
  question: Question
}) {
  // If any options are marked exclusive, the user can proceed if any exclusive option is selected.
  if (isExclusiveOptionSelected({ curResponse, question })) {
    return true
  }

  // If a min is set, the user must select that number of options to continue.
  if (question.constraints.min) {
    return curResponse.length >= question.constraints.min
  }

  // If a max is set and no min, the user must select at least 1 option to continue.
  if (question.constraints.max) {
    return curResponse.length >= 1
  }

  // If no min-max is set, the user has to rank all options that are not marked exclusive.
  const numNonExclusiveOptions =
    question.options.length - question.constraints.exclusiveOptionIDs.size

  return curResponse.length >= numNonExclusiveOptions
}

export function isOptionDisabled({
  curResponse,
  option,
  question,
}: {
  curResponse: Question['response']
  option: Question['options'][number]
  question: Question
}) {
  // If the option has been ranked, we don't want to disable it because we want the
  // user to be able to unrank it.
  if (curResponse.includes(option.id)) {
    return false
  }

  if (
    question.constraints.max &&
    curResponse.length === question.constraints.max
  ) {
    return true
  }

  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  const hasEveryRequiredImageBeenViewed = question.options.every(
    (option) =>
      option.type === 'text' ||
      !option.constraints.requireView ||
      option.image.viewed,
  )
  if (
    question.constraints.requireImageViews &&
    !hasEveryRequiredImageBeenViewed
  ) {
    return true
  }

  if (isExclusiveOptionSelected({ curResponse, question })) {
    return true
  }

  return false
}
