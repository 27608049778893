import { hasLabelFeature } from './questions'
import { Question } from 'types/glass-api/domainModels'
import { Survey } from './surveys'

export function buildExclusiveMatrixOptionIDs({
  question,
  survey,
}: {
  question: Question
  survey: Survey
}) {
  if (survey.features.useNewMatrixOptions) {
    return new Set<string>(
      question.matrixOptions
        .filter((option) => option.isExclusive)
        .map(({ id }) => `${id}`),
    )
  }

  return new Set<string>(
    question.labels
      ?.filter((label) => hasLabelFeature({ code: 'QOE01', label }))
      .map(({ id }) => `${id}`) ?? [],
  )
}

export function buildExclusiveOptionIDs({ question }: { question: Question }) {
  return new Set<string>(
    question.options
      .filter(({ exclusive }) => exclusive)
      .map(({ id }) => `${id}`),
  )
}
