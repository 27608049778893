import { clsx } from 'clsx'
import { map, compact, orderBy } from 'lodash-es'
import { ReactNode } from 'react'

import {
  BlockSetMonadic,
  getMonadicBlockForQuestion,
  getMonadicPromptIDForIteration,
  getMonadicViewedConceptIDs,
  isMonadicBlockQuestion,
} from 'utils/questionBlocks'
import {
  Condition,
  didSatisfyCondition,
  getPreviousItem,
} from 'utils/conditions'
import {
  getIterationFromID,
  getPreviousQuestionForIteration,
  getResponsesArray,
  Question,
} from 'utils/questions'
import { titlesFromSet } from 'utils/general'

import ListDiscIndented from './ListDiscIndented'

const ConditionsTable = ({
  condition,
  monadicBlocks,
  question,
  questionsCurrent,
  questionsInitial,
}: {
  condition: Condition
  monadicBlocks: Record<string, BlockSetMonadic>
  question?: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) => {
  const { expectedResponse, item, logic } = condition
  const iteration = question ? getIterationFromID(question.id) : undefined
  let previousItem = getPreviousItem({
    item,
    iteration,
    monadicBlocks,
    questions: questionsCurrent,
  })
  let hadToFallback = false

  // If we couldn't find a previous item and the condition was searching for a question
  // for a specific concept of a monadic loop, then the concept was probably not shown
  // to the respondent. We try finding the question again without specifying a concept
  // so that we can show the configuration to the user in this debugging table.
  if (!previousItem && item.type === 'question' && item.conceptId) {
    previousItem = getPreviousItem({
      item: { ...item, conceptId: null },
      iteration,
      monadicBlocks,
      questions: questionsCurrent,
    })
    hadToFallback = true
  }

  let actualResponse: string[] = []
  let previousQuestionInitial: Question | undefined
  let expectedConcept = ''
  let expectedConceptMatch = true

  if (previousItem?.type === 'blockSetMonadic') {
    const responseData = getMonadicResponseData({
      blockSetMonadic: previousItem.data,
      iteration,
      questionsCurrent,
      questionsInitial,
    })

    actualResponse = responseData.actualResponse
    previousQuestionInitial = responseData.previousQuestionInitial
  } else if (previousItem?.type === 'question') {
    const responseData = getQuestionResponseData({
      expectedResponse,
      iteration,
      previousQuestions: previousItem.data,
      questionsInitial,
    })

    // If we had to fallback to a previous item, then the respondent probably didn't
    // see the specified item in the condition. Therefore, they didn't have an actual
    // response to the question.
    actualResponse = hadToFallback ? [] : responseData.actualResponse
    previousQuestionInitial = responseData.previousQuestionInitial

    const monadicConcept = getExpectedMonadicConcept({
      item,
      iteration,
      monadicBlocks,
      previousQuestionID: previousItem.data[0].id,
      question,
      questionsInitial,
      questionsCurrent,
    })
    expectedConcept = monadicConcept.expected
    expectedConceptMatch = monadicConcept.match
  }

  return (
    <table className="w-full">
      <thead>
        <tr className="text-left text-xs font-bold uppercase text-gray-400">
          <th className="w-[180px]">Question</th>
          <th className="w-[100px]"></th>
          <th className="w-[400px]">Expected</th>
          <th>Response</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="px-1 align-top">
            {previousQuestionInitial?.testing.label}{' '}
            {expectedConcept ? (
              <span className={clsx({ 'text-red-500': !expectedConceptMatch })}>
                {expectedConcept}
              </span>
            ) : null}
          </td>
          <td className="align-top text-sm font-semibold">
            {logic === 'is'
              ? 'is equal to'
              : logic === 'should'
                ? 'is either'
                : logic === 'isnt'
                  ? 'is not'
                  : null}
          </td>
          <td className="align-top">
            {previousQuestionInitial && (
              <ExpectedResponseCell
                expectedResponse={expectedResponse}
                question={previousQuestionInitial}
              />
            )}
          </td>
          <td
            className={clsx('align-top', {
              'text-red-500': !didSatisfyCondition({
                curIteration: iteration,
                expectedResponse,
                logic,
                monadicBlocks,
                previousItem,
                questions: questionsCurrent,
              }),
            })}
          >
            <ListDiscIndented>
              {orderBy(actualResponse).map((response, i) => (
                <li key={i}>{response}</li>
              ))}
            </ListDiscIndented>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default ConditionsTable

const ExpectedResponseCell = ({
  expectedResponse,
  question,
}: {
  expectedResponse: Condition['expectedResponse']
  question: Question
}) => {
  let expectedItems: ReactNode[] = []

  if (
    question.type === 'ideaPresenter' &&
    expectedResponse.type === 'rangeByID'
  ) {
    expectedItems = titlesFromSet({
      ids: new Set(
        Object.values(expectedResponse.value).flatMap((ranges) =>
          ranges.map((range) => `${range[0]}`),
        ),
      ),
      resources: question.concepts.map((concept) => {
        return { id: concept.id, title: concept.alt }
      }),
    })
  } else if (question.type === 'matrix') {
    if (expectedResponse.type === 'titlesByID') {
      expectedItems = orderBy(
        map(expectedResponse.value, (optionTitles, id) => {
          const statement = question.statements.find(
            (statement) => statement.id === id,
          )

          return statement ? (
            <span>
              {statement.title}: {optionTitles.join(', ')}
            </span>
          ) : null
        }),
      )
    } else if (expectedResponse.type === 'nestedIDs') {
      expectedItems = orderBy(
        map(expectedResponse.value, (optionIDs, id) => {
          const statement = question.statements.find(
            (statement) => statement.id === id,
          )
          const optionTitles = titlesFromSet({
            ids: optionIDs,
            resources: question.options,
          })

          return statement ? (
            <span>
              {statement.title}: {optionTitles.join(', ')}
            </span>
          ) : null
        }),
      )
    }
  } else if (
    question.type === 'multipleChoice' &&
    expectedResponse.type === 'setOfIDs'
  ) {
    expectedItems = titlesFromSet({
      ids: expectedResponse.value,
      resources: question.options,
    })
  } else if (
    question.type === 'openEnded' &&
    expectedResponse.type === 'range'
  ) {
    expectedItems = expectedResponse.value.map((range) => {
      return `${range[0]}-${range[1]}`
    })
  } else if (
    (question.type === 'bipolar' ||
      question.type === 'ranking' ||
      question.type === 'scale') &&
    expectedResponse.type === 'rangeByID'
  ) {
    const resources =
      question.type === 'bipolar' || question.type === 'ranking'
        ? question.options
        : question.scales
    const titlesToRange = compact(
      map(expectedResponse.value, (range, id) => {
        const resourceIndex = resources.findIndex(
          (resource) => resource.id === id,
        )
        if (resourceIndex === -1) {
          return
        }

        let title = resources[resourceIndex].title
        if (question.type === 'scale' && !title) {
          title = `Scale ${resourceIndex + 1}`
        }

        return { range, title }
      }),
    )

    expectedItems = orderBy(titlesToRange, 'title').map(({ title, range }) => {
      let wordSeparator = 'between'
      if (question.type === 'ranking') {
        wordSeparator = 'ranked between'
      }

      return (
        <span key={title}>
          {title} <span className="text-sm italic">{wordSeparator}</span>{' '}
          {range.map((entry) => `${entry[0]}-${entry[1]}`).join(', and ')}
        </span>
      )
    })
  }

  return (
    <ListDiscIndented>
      {expectedItems.map((item, i) => (
        <li key={i}>{item}</li>
      ))}
    </ListDiscIndented>
  )
}

/**
 * Gets a concept label if the specified condition was referring to a question inside
 * a monadic block.
 */
function getExpectedMonadicConcept({
  item,
  iteration,
  monadicBlocks,
  previousQuestionID,
  question,
  questionsCurrent,
  questionsInitial,
}: {
  item: Condition['item']
  iteration?: string
  monadicBlocks: Record<string, BlockSetMonadic>
  previousQuestionID: string
  question?: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) {
  const blockSetMonadic = getMonadicBlockForQuestion({
    monadicBlocks,
    questionID: previousQuestionID,
  })
  if (!blockSetMonadic || !('conceptId' in item)) {
    return { expected: '', match: true }
  }

  const isCurrentlyInsideMonadicBlock = question
    ? isMonadicBlockQuestion({ monadicBlocks, questionID: question.id })
    : false

  if (!item.conceptId) {
    return {
      expected: isCurrentlyInsideMonadicBlock
        ? '[Current Concept]'
        : '[Any Concept]',
      match: true,
    }
  }

  const promptID = getMonadicPromptIDForIteration({
    blockSetMonadic,
    iteration: iteration || '0',
  })
  if (!promptID) {
    return { expected: '[Unknown Concept]', match: false }
  }

  const expectedConcept = questionsInitial[promptID]?.concepts.find(
    ({ id }) => id === item.conceptId,
  )
  const actualConcept = questionsCurrent[promptID]?.concepts.find(
    ({ id }) => id === item.conceptId,
  )
  if (!expectedConcept) {
    return { expected: '[Unknown Concept]', match: false }
  }

  return {
    expected: `[${expectedConcept.alt}]`,
    match: isCurrentlyInsideMonadicBlock
      ? expectedConcept.alt === actualConcept?.alt
      : !!actualConcept,
  }
}

/**
 * Gets the following data:
 *   - The prompt question for the monadic block - either the one for the current
 * iteration or the initial prompt.
 *   - The seen concept(s) for either the current iteration or all iterations.
 */
function getMonadicResponseData({
  blockSetMonadic,
  iteration,
  questionsCurrent,
  questionsInitial,
}: {
  blockSetMonadic: BlockSetMonadic
  iteration?: string
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) {
  const promptQuestionID = getMonadicPromptIDForIteration({
    blockSetMonadic,
    iteration: iteration || '0',
  })

  const previousQuestionInitial = promptQuestionID
    ? questionsInitial[promptQuestionID]
    : undefined

  const viewedConceptIDs = getMonadicViewedConceptIDs({
    blockSetMonadic,
    iteration: iteration ? { id: iteration, logic: 'eq' } : undefined,
    questions: questionsCurrent,
  })

  const actualResponse =
    previousQuestionInitial?.concepts
      .filter(({ id }) => viewedConceptIDs.has(id))
      .map(({ alt }) => alt) ?? []

  return { actualResponse, previousQuestionInitial }
}

/**
 * Gets the following data:
 *   - The configuration of the previous question.
 *   - The responses to the previous questions - we may have multiple questions
 * if we are looking for a question that was part of a monadic loop and was therefore
 * potentially shown multiple times.
 */
function getQuestionResponseData({
  expectedResponse,
  iteration,
  previousQuestions,
  questionsInitial,
}: {
  expectedResponse: Condition['expectedResponse']
  iteration?: string
  previousQuestions: Question[]
  questionsInitial: Record<string, Question>
}) {
  const previousQuestionInitial = getPreviousQuestionForIteration({
    iteration,
    previousQuestionID: previousQuestions[0].id,
    questions: questionsInitial,
  })

  const actualResponse = previousQuestions.flatMap((previousQuestion) => {
    return getResponsesArray({
      question: removeNonExpectedResources({
        expectedResponse,
        question: previousQuestion,
      }),
    })
  })

  return { actualResponse, previousQuestionInitial }
}

/**
 * Returns a modified question that only includes the resources that are present
 * in the expectedResponse parameter. This modifies the provided question so it's
 * easier to compare against the expected response because extraneous resources are
 * removed.
 */
function removeNonExpectedResources({
  expectedResponse,
  question,
}: {
  expectedResponse: Condition['expectedResponse']
  question: Question
}) {
  if (question.type === 'bipolar' && expectedResponse.type === 'rangeByID') {
    return {
      ...question,
      options: question.options.filter(
        ({ id }) => !!expectedResponse.value[id],
      ),
    } satisfies Question
  } else if (
    question.type === 'matrix' &&
    (expectedResponse.type === 'titlesByID' ||
      expectedResponse.type === 'nestedIDs')
  ) {
    return {
      ...question,
      statements: question.statements.filter(
        ({ id }) => !!expectedResponse.value[id],
      ),
    } satisfies Question
  } else if (
    question.type === 'ranking' &&
    expectedResponse.type === 'rangeByID'
  ) {
    return {
      ...question,
      options: question.options.filter(
        ({ id }) => !!expectedResponse.value[id],
      ),
    } satisfies Question
  } else if (
    question.type === 'scale' &&
    expectedResponse.type === 'rangeByID'
  ) {
    return {
      ...question,
      scales: question.scales.filter(({ id }) => !!expectedResponse.value[id]),
    } satisfies Question
  }

  return question
}
