import { compact, size } from 'lodash-es'

import { buildCarryForwardFeature, CarryForwardFeature } from './carryForward'
import {
  buildConceptDisplayLogic,
  buildOptionsDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import {
  buildOptionDisplayXOfYFeature,
  DisplayXOfYFeature,
} from './displayXOfY'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import { buildRandomizeOptionsFeature, RandomizeFeature } from './randomize'
import {
  buildTestLabel,
  getFeatureNumberRangeValue,
  hasFeature,
  OPTION_TYPES,
  shouldHideImageTitles,
} from './questions'
import { Concept, getConcepts } from './concepts'
import { getRequireSumError } from './sums'
import { getRequireViewConceptError } from './requireViewing'
import { Image } from './media'
import {
  Option,
  OptionScale,
  Question as QuestionAPI,
  SCALE_TYPES,
  SCALE_UNITS,
} from 'types/glass-api/domainModels'

export type Question = {
  concepts: Concept[]
  constraints: {
    requireConceptView: boolean
    sum: number | null
  }
  directions: string
  features: {
    carryForward: CarryForwardFeature | undefined
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    displayXOfY: DisplayXOfYFeature | undefined
    inverted: boolean
    pipeConcept: PipeConceptFeature | undefined
    randomizeScales: RandomizeFeature | undefined
    scaleDisplayLogic: DisplayLogicByResource
  }
  id: string
  // This is a mapping of the scale ID to the selected value for the scale.
  response: Record<string, number>
  scales: Scale[]
  testing: { label: string }
  title: string
  type: 'scale'
}

export type Scale = {
  displayType: 'radio' | 'slider'
  features: { hideTitle: boolean }
  id: string
  image?: Image
  labels: [string, string, string]
  range: {
    max: number
    min: number
    step: number
  }
  title: string
  unit?: 'dollars' | 'percentages'
}

export function canContinue({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  return (
    size(curResponse) === question.scales.length &&
    !getRequireSumError({ curResponse, question }) &&
    !getRequireViewConceptError({ question })
  )
}

export function getDisplayError({ question }: { question: Question }) {
  return question.scales.length > 0 ? false : 'no scales'
}

export function getFormattedScaleValue({
  unit,
  value,
}: {
  unit: Scale['unit']
  value: number
}) {
  if (unit === 'dollars') {
    return `$${value}`
  } else if (unit === 'percentages') {
    return `${value}%`
  }

  return `${value}`
}

/**
 * Returns the initial position for the thumb of a slider scale. We try to put the
 * thumb in the middle of the scale.
 */
export function getInitialSliderPosition({
  max,
  min,
  step,
}: {
  max: number
  min: number
  step: number
}) {
  const minAdjustedMiddle = min + Math.floor((max - min) / 2)
  const remainder = minAdjustedMiddle % step

  // Imagine a range of [0, 15]. This results in a middle of 7 (after being floored).
  // If the step is 3, then we need to subtract one from the value of 7 to get 6 which
  // is a valid step (3 * 2).
  return remainder === 0 ? minAdjustedMiddle : minAdjustedMiddle - remainder
}

export function getQuestion({
  id,
  question,
  questionsConfiguration,
  useNewMatrixOptions = false,
}: {
  id: string
  question: QuestionAPI
  questionsConfiguration: Record<number, QuestionAPI>
  useNewMatrixOptions?: boolean
}) {
  return {
    concepts: getConcepts({ question }),
    constraints: {
      requireConceptView: hasFeature({ code: 'VAL02', question }),
      sum: getFeatureNumberRangeValue({ code: 'SUM01', question }),
    },
    directions: question.description,
    features: {
      carryForward: buildCarryForwardFeature({
        question,
        questionsConfiguration,
        useNewMatrixOptions,
      }),
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      displayXOfY: buildOptionDisplayXOfYFeature({ question }),
      inverted: hasFeature({ code: 'RMO', question }),
      pipeConcept: buildPipeConceptFeature({ question }),
      randomizeScales: buildRandomizeOptionsFeature({ question }),
      scaleDisplayLogic: buildOptionsDisplayLogic({ question }),
    },
    id,
    response: {},
    scales: compact(
      question.options.map((option) => {
        if (!isScaleOption(option)) {
          return
        }

        const isImage = question.contentTypeId === OPTION_TYPES.IMAGE
        const title = isImage ? option.description ?? '' : option.title

        return {
          displayType:
            option.scaleTypeId === SCALE_TYPES.RADIO ? 'radio' : 'slider',
          features: { hideTitle: shouldHideImageTitles(question) },
          id: `${option.id}`,
          image:
            isImage && option.dataUrl
              ? {
                  alt: title,
                  publicID: option.dataUrl.public_id,
                }
              : undefined,
          labels: [option.lowLabel, option.middleLabel ?? '', option.highLabel],
          range: {
            max: option.high,
            min: option.low,
            step: option.step,
          },
          title,
          unit:
            option.scaleUnitId === SCALE_UNITS.DOLLARS
              ? 'dollars'
              : option.scaleUnitId === SCALE_UNITS.PERCENTAGES
                ? 'percentages'
                : undefined,
        } satisfies Scale
      }),
    ),
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'scale' as const,
  } satisfies Question
}

export function isScaleOption(option: Option): option is OptionScale {
  return (
    option.high !== null &&
    option.highLabel !== null &&
    option.low !== null &&
    option.lowLabel !== null &&
    option.scaleTypeId !== null &&
    option.scaleUnitId !== null &&
    option.step !== null
  )
}

export function isScaleDisabled({ question }: { question: Question }) {
  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  return false
}
