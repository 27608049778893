import { cloneDeep, orderBy } from 'lodash-es'

import {
  getIterationFromID,
  getPreviousQuestionForIteration,
  Question,
} from './questions'
import { Image } from './media'
import { Question as APIQuestion } from 'types/glass-api/domainModels'

export type CarryForwardFeature = {
  newResourceIDs: Set<string>
  newResourceIDToPreviousResourceID: Record<string, string>
  questionID: string
  type:
    | { name: 'displayed' }
    // For matrix questions, the user can configure the feature to only carry over
    // statements where the selected or not-selected option is a specific one.
    // For example: Only carry over statements where the selected option was "Definitely Agree".
    | { name: 'notSelected'; optionID?: string }
    | { name: 'selected'; optionID?: string }
}

interface ResourceWithDisplayProperties {
  id: string
  image?: Image
  title: string
}

/**
 * Converts the API carry forward feature into a format that's more readable and
 * easier to work with.
 */
export function buildCarryForwardFeature({
  question,
  questionsConfiguration,
  useNewMatrixOptions = false,
}: {
  question: APIQuestion
  questionsConfiguration: Record<number, APIQuestion>
  useNewMatrixOptions?: boolean
}) {
  const carryForwardFeature = findCarryForwardFeature(question)
  if (!carryForwardFeature || !carryForwardFeature.enumValue) {
    return
  }

  const newResourceIDs = new Set<string>()
  const newResourceIDToPreviousResourceID: Record<string, string> = {}

  // Not all configured options for the question have to be carried forward from a previous question -
  // we could have new ones as well. That's what the newResourceIDs represents. And the
  // newResourceIDToPreviousResourceID mapping allows us to grab display properties from the old resource
  // when carrying forward.
  question.options.forEach((option) => {
    if (option.carryOverParentId) {
      newResourceIDToPreviousResourceID[`${option.id}`] =
        `${option.carryOverParentId}`
    } else {
      newResourceIDs.add(`${option.id}`)
    }
  })

  let type: CarryForwardFeature['type'] = { name: 'displayed' }
  if (carryForwardFeature.feature.code === 'CON01') {
    type = { name: 'notSelected' }
  } else if (carryForwardFeature.feature.code === 'COS01') {
    type = { name: 'selected' }
  }

  // For matrix questions, the user can choose to only carry forward not-selected or selected
  // statements when the option is a certain option.
  if (type.name === 'notSelected' || type.name === 'selected') {
    if (useNewMatrixOptions && carryForwardFeature.matrixOptionId) {
      const previousQuestion =
        questionsConfiguration[carryForwardFeature.enumValue]
      const option = previousQuestion.matrixOptions.find(
        ({ id }) => id === carryForwardFeature.matrixOptionId,
      )
      if (option) {
        type.optionID = `${option.id}`
      }
    } else if (carryForwardFeature.enumRegex) {
      const previousQuestion =
        questionsConfiguration[carryForwardFeature.enumValue]
      const option = previousQuestion.labels?.find(
        ({ optionLabel }) => optionLabel === carryForwardFeature.enumRegex,
      )
      if (option) {
        type.optionID = `${option.id}`
      }
    }
  }

  return {
    newResourceIDs,
    newResourceIDToPreviousResourceID,
    questionID: `${carryForwardFeature.enumValue}`,
    type,
  } satisfies CarryForwardFeature
}

/**
 * Returns a copy of the provided question with resources modified if the question needs to
 * carry over resources from a previous question.
 */
export function carryForward<QuestionType extends Question>({
  nextQuestion,
  questions,
}: {
  nextQuestion: QuestionType
  questions: Record<string, Question>
}) {
  if (
    (nextQuestion.type !== 'matrix' &&
      nextQuestion.type !== 'multipleChoice' &&
      nextQuestion.type !== 'ranking' &&
      nextQuestion.type !== 'scale') ||
    !nextQuestion.features.carryForward
  ) {
    return nextQuestion
  }

  const { carryForward } = nextQuestion.features

  const previousQuestion = getPreviousQuestionForIteration({
    iteration: getIterationFromID(nextQuestion.id),
    previousQuestionID: carryForward.questionID,
    questions,
  })

  if (
    !previousQuestion ||
    (previousQuestion.type !== 'matrix' &&
      previousQuestion.type !== 'multipleChoice' &&
      previousQuestion.type !== 'ranking' &&
      previousQuestion.type !== 'unaidedAwareness')
  ) {
    return nextQuestion
  }

  const nextResources =
    nextQuestion.type === 'scale'
      ? nextQuestion.scales
      : nextQuestion.type === 'matrix'
        ? nextQuestion.statements
        : nextQuestion.options
  const previousResources =
    previousQuestion.type === 'matrix'
      ? previousQuestion.statements
      : previousQuestion.type === 'unaidedAwareness'
        ? // We make fake previous resources based on how many responses the respondent gave.
          // Each response is its own resource.
          previousQuestion.legacyOptionIDs.map((id, i) => ({
            id,
            title: previousQuestion.response[i],
          }))
        : previousQuestion.options

  const sortedResources = sortNewResourcesByPrevious({
    carryForward,
    nextResources,
    previousResources,
  })
  const filteredResources = filterBySelectionType({
    carryForward,
    nextResources: sortedResources,
    previousResources,
    response:
      previousQuestion.type === 'matrix'
        ? { type: 'mapping', value: previousQuestion.response }
        : previousQuestion.type === 'unaidedAwareness'
          ? {
              type: 'set',
              value: new Set(
                previousQuestion.legacyOptionIDs.filter(
                  (_, idx) => previousQuestion.response[idx],
                ),
              ),
            }
          : { type: 'set', value: new Set(previousQuestion.response) },
  })
  const resourcesWithDisplayProps = copyDisplayPropertiesFromPrevious({
    carryForward,
    nextResources: filteredResources,
    previousResources,
    responseFreeText:
      previousQuestion.type === 'matrix' ||
      previousQuestion.type === 'multipleChoice'
        ? previousQuestion.responseFreeText
        : undefined,
  })

  if (nextQuestion.type === 'matrix') {
    return {
      ...nextQuestion,
      statements: resourcesWithDisplayProps,
    }
  } else if (nextQuestion.type === 'scale') {
    return { ...nextQuestion, scales: resourcesWithDisplayProps }
  }

  return { ...nextQuestion, options: resourcesWithDisplayProps }
}

/**
 * Filters the nextResources array to include new resources and ones that satisfy the
 * configured carry forward type (e.g. selected, not selected, displayed).
 */
function filterBySelectionType<ResourcesType extends { id: string }[]>({
  carryForward,
  nextResources,
  previousResources,
  response,
}: {
  carryForward: CarryForwardFeature
  nextResources: ResourcesType
  previousResources: ResourcesType
  response:
    | { type: 'mapping'; value: Record<string, Set<string>> }
    | { type: 'set'; value: Set<string> }
}) {
  return nextResources.filter(({ id }) => {
    // The user can always add new resources other than the ones
    // that are carried over.
    if (carryForward.newResourceIDs.has(id)) {
      return true
    }

    const previousResourceID =
      carryForward.newResourceIDToPreviousResourceID[id]
    const previousResourceWasDisplayed = previousResources.some(
      ({ id }) => id === previousResourceID,
    )

    // In all cases, we only want to carry over resources that were displayed. Even for "selected" and
    // "not-selected" configurations since if it wasn't displayed, it wasn't available for selection.
    if (!previousResourceWasDisplayed) {
      return false
    }

    if (carryForward.type.name === 'displayed') {
      return true
    }

    let hasSelection = false
    if (response.type === 'set') {
      hasSelection = response.value.has(previousResourceID)
    } else if (response.type === 'mapping') {
      const resourceSelections =
        response.value[previousResourceID] ?? new Set<string>()
      const optionID = carryForward.type.optionID

      // We're checking for each a specific option selection here or
      // any option selection if the user didn't specify one.
      hasSelection = optionID
        ? resourceSelections.has(optionID)
        : resourceSelections.size > 0
    }

    return carryForward.type.name === 'selected' ? hasSelection : !hasSelection
  }) as ResourcesType
}

export function findCarryForwardFeature(question: APIQuestion) {
  return question.questionFeatures.find(({ feature }) => {
    return (
      feature.code === 'COD01' ||
      feature.code === 'CON01' ||
      feature.code === 'COS01'
    )
  })
}

/**
 * Copies display properties (e.g. title, image, free-text response) from the previous
 * resource into the new resource (although leaves new resources alone).
 */
function copyDisplayPropertiesFromPrevious<
  ResourcesType extends ResourceWithDisplayProperties[],
>({
  carryForward,
  nextResources,
  previousResources,
  responseFreeText,
}: {
  carryForward: CarryForwardFeature
  nextResources: ResourcesType
  previousResources: ResourcesType
  responseFreeText?:
    | Record<string, Record<string, string>>
    | Record<string, string>
}) {
  return nextResources.map((nextResource) => {
    // If this is a new resource, it doesn't reference a previous resource so we
    // don't need to copy over any display properties.
    if (carryForward.newResourceIDs.has(nextResource.id)) {
      return nextResource
    }

    const previousResource = previousResources.find(
      ({ id }) =>
        id === carryForward.newResourceIDToPreviousResourceID[nextResource.id],
    )
    if (!previousResource) {
      return nextResource
    }

    let previousFreeTextResponse = responseFreeText?.[previousResource.id] ?? ''

    // In the case of a matrix question, the free text for a previous resource (i.e. a previous statement)
    // is an object where the key is the statement ID and the value another object with option IDs as keys
    // and free-text as values.
    //
    // For example:
    // { [statementID]: { [option1ID]: freeText, [option2ID]: freeText } }
    //
    // It's actually not currently possible for a matrix question to have multiple options with free-text
    // responses, but it could be possible in the future.
    if (typeof previousFreeTextResponse !== 'string') {
      previousFreeTextResponse = Object.values(previousFreeTextResponse)
        .filter((freeText) => freeText)
        .join(', ')
    }

    const title = previousFreeTextResponse
      ? `${previousResource.title} (${previousFreeTextResponse})`
      : previousResource.title

    const modifiedNextResource = { ...nextResource, title }

    // Not all previous resources will have images, but if this one does,
    // we want to set the image properties on the new resource.
    if (
      'image' in previousResource &&
      previousResource.image &&
      modifiedNextResource.image
    ) {
      modifiedNextResource.image.alt = previousResource.image.alt
      modifiedNextResource.image.publicID = previousResource.image.publicID
    }

    return modifiedNextResource
  })
}

/**
 * Sorts the nextResources by how they were ordered in the previous question, while also
 * keeping new resources in their respective order.
 */
function sortNewResourcesByPrevious<ResourcesType extends { id: string }[]>({
  carryForward,
  nextResources,
  previousResources,
}: {
  carryForward: CarryForwardFeature
  nextResources: ResourcesType
  previousResources: ResourcesType
}) {
  const previousResourceIDs = Object.values(
    carryForward.newResourceIDToPreviousResourceID,
  )

  // When a user configures the carry forward feature, they can choose to not carry over
  // all the resources from a previous question. This array represents only the previous
  // resources that they chose to carry forward.
  const previousResourcesInNewResources = cloneDeep(previousResources).filter(
    (previousResource) => {
      return previousResourceIDs.some((id) => id === previousResource.id)
    },
  )

  // Orders the resources by how they were ordered in the previous question. For example,
  // the previous question may have had resources: Option 2, Option 1, and Option 3 (e.g. due
  // to randomization). Our new resources may be: Option 1, Option 2, Option 3, and New Option to start.
  // We want this to be ordered as: Option 2, Option 1, Option 3, and New Option.
  const orderedPreviousResources = orderBy(
    nextResources.filter(
      (nextResource) => !carryForward.newResourceIDs.has(nextResource.id),
    ),
    (nextResource) => {
      const previousResourceID =
        carryForward.newResourceIDToPreviousResourceID[nextResource.id]

      return previousResourcesInNewResources.findIndex(
        ({ id }) => id === previousResourceID,
      )
    },
    ['asc'],
  )

  const orderedAllResources = [] as unknown as ResourcesType

  // Now that we've ordered our resources by how they appeared in the previous question,
  // we need to combine these with the new resources, while maintaining the order specified
  // for the new resources.
  for (let i = 0; i < nextResources.length; i++) {
    const nextResource = nextResources[i]
    if (carryForward.newResourceIDs.has(nextResource.id)) {
      orderedAllResources.push(nextResource)
    } else {
      const previousResource = orderedPreviousResources.shift()
      if (previousResource) {
        orderedAllResources.push(previousResource)
      }
    }
  }

  return orderedAllResources
}
